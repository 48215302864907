@font-face {
    font-family: "ArialBlackFallback";
    src: url("../fonts/ariblk.eot"); /* IE9 Compat Modes */
    src: url("../fonts/ariblk.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url("../fonts/ariblk.otf") format("opentype"), /* Open Type Font */
        url("../fonts/ariblk.svg") format("svg"), /* Legacy iOS */
        url("../fonts/ariblk.ttf") format("truetype"), /* Safari, Android, iOS */
        url("../fonts/ariblk.woff") format("woff"), /* Modern Browsers */
        url("../fonts/ariblk.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: "ArialFallback";
    src: url("../fonts/arial.eot"); /* IE9 Compat Modes */
    src: url("../fonts/arial.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url("../fonts/arial.otf") format("opentype"), /* Open Type Font */
        url("../fonts/arial.svg") format("svg"), /* Legacy iOS */
        url("../fonts/arial.ttf") format("truetype"), /* Safari, Android, iOS */
        url("../fonts/arial.woff") format("woff"), /* Modern Browsers */
        url("../fonts/arial.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}