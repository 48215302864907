.footer {
  padding-bottom: 2rem;
  padding-top: 2rem; // 6rem
  background-color: #39414d;
  color: #fff;
}

.logo {
  border-bottom: 2px solid #fff;
  padding: 0 0 1rem;
  margin: 0 0 1rem;
  
  & img {
    max-width: 130;
  }
}

.blurb {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
}

.main {
  & h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #fff;
  }

  & ul {
    list-style: none;
    padding: 0;
    
    & li {
      margin-bottom: 1rem;
      font-size: 0.875rem;

      & a {
        color: #fff;
        text-transform: none;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}

.disclaimer {
  margin-bottom: 1rem;

  & p:first-of-type {
    margin-top: 1.5rem;
    margin-bottom: .3rem;
    font-weight: 700;
  }

  & p:last-of-type {
    margin-top: 1rem;
  }
  
  & p {
    color: #c6c9ca;
    font-size: 0.75rem;
  }
}

.secondary {
  font-size: 0.75rem;
  margin-bottom: 0.75rem;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }

  & a {
    color: #fff !important;
    text-transform: none !important;
    text-decoration: none;
  }
}