.consent {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #39414d;
  color: white;
  font-size: 1rem;
  opacity: 0;
  transition: 1s opacity ease-in-out;
  z-index: 2000;

  .text { color: #c5c5c5; }
}