
$headings-font-family:        "ArialBlackFallback";
$font-family-sans-serif:      "ArialFallback";

$primary:                     #ff6400;
$border-color:                #d9d9d9;
$hr-border-color:             #d9d9d9;
$grid-gutter-width:           0;

$btn-border-width:            2px;
$btn-border-radius:           1000px;
$btn-border-radius-lg:        1000px;
$btn-border-radius-sm:        1000px;
$btn-padding-x:               1.33rem;
$btn-padding-x-sm:            0.85rem;

$navbar-nav-link-padding-x:   0.7rem;
$navbar-dark-toggler-border-color: transparent;
$enable-caret:                true;
$caret-spacing:               8px;


$custom-switch-width-lg:                           1.25rem * 1.75;
$custom-switch-indicator-border-radius-lg:         1.25rem / 2;
$custom-switch-indicator-size-lg:                  subtract(1.25rem, 1px * 4);