@include media-breakpoint-down(md) {
  html { font-size: 14px; }
}

@include media-breakpoint-down(sm) {
  .dropdown-menu {
    background: none;
    border: none;

    .dropdown-item {
      color: $navbar-dark-color;

      &:hover, &:focus {
        background: none;
        color: $navbar-dark-hover-color;
      }
    }
  }
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

div#root {
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

div#app {
  box-shadow: 0px 0px 3px #cccccc;
  background-color: white;
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.vr {
  background-color: $border-color;
  width: 1px;
  margin-left: -1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.gap
{
  height: $spacer;
}

.gradient-bg
{
  background: $orange;
  background: linear-gradient(90deg, #A5203F 0%, #FF6400 34%, #FFA100 69%, #FFC800 100%);
}

.navbar {
  .navbar-brand
  {
    padding-top: 0px;
  }

  .nav-link
  {
    padding-top: 0.66rem;
    padding-bottom: 0.34rem;
  }
}


.nav.nav-underline
{
  .nav-link
  {
    //color: $body-color;
    border-bottom: solid 3px transparent;
  }

  .nav-link.active
  {
    background-color: transparent;
    border-bottom: solid 3px;
    color: $component-active-bg;
    border-radius: 0px;
  }

  .nav-link.invalid {
    color: $form-feedback-invalid-color;
    font-weight: bold;

    padding-right: $input-height-inner;
    background-image: escape-svg($form-feedback-icon-invalid);
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;

    &.active {
      border-color: $form-feedback-invalid-color;
    }
  }
}


.pseudo-link
{
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  cursor: pointer;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}


h2 {
  font-size: 1.125rem;
  font-weight: 700;
  font-family: $headings-font-family;
  text-transform: uppercase;
}

h3 {
  font-size: 1.125rem;
  font-weight: 700;
  font-family: $font-family-sans-serif;
  color: $primary;
}


.display-1 {
  font-size: 3.5rem;
}

.display-3 {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: $headings-font-family;
}

.display-4 {
  font-size: 1.125rem;
  font-weight: bolder;
}


.custom-switch-lg {
  padding-left: $custom-switch-width-lg + $custom-control-gutter;
  user-select: none;

  .custom-control-label {
    &::before {
      left: -($custom-switch-width-lg + $custom-control-gutter);
      width: $custom-switch-width-lg;
      height: add($custom-switch-indicator-size-lg, $custom-control-indicator-border-width * 4);
      pointer-events: all;
      // stylelint-disable-next-line property-blacklist
      border-radius: $custom-switch-indicator-border-radius-lg;
    }

    &::after {
      top: add(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2, $custom-control-indicator-border-width * 2);
      left: add(-($custom-switch-width-lg + $custom-control-gutter), $custom-control-indicator-border-width * 2);
      width: $custom-switch-indicator-size-lg;
      height: $custom-switch-indicator-size-lg;
      background-color: $custom-control-indicator-border-color;
      // stylelint-disable-next-line property-blacklist
      border-radius: $custom-switch-indicator-border-radius-lg;
      @include transition(transform .15s ease-in-out, $custom-forms-transition);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX(subtract($custom-switch-width-lg - $custom-control-indicator-size, $custom-control-indicator-border-width * 4));
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}



.input-group {
  & > .form-control:not(:last-child):not(.is-invalid), .input-group > .custom-select:not(:last-child):not(.is-invalid) {
    border-right: 0;
  }
  .input-group-text {
    background: transparent;
  }
}




.big-sun {
  padding-top: 12px;
  text-align: center;

  width: 256px;
  height: 256px;
  background: linear-gradient(to top, #A5203F, #A5203F, #CF453C, #FF6400, #F78D26, #FBAB17, #FDBF0C, #FDBF0C);
  background-size: 100% 800%;
  border-radius: 100%;
  animation: 8s rise infinite;
  animation-delay: 1s;

  position: absolute;
  top: 110%;
  background-position: 50% 100%;


  .label {
    font-family: $headings-font-family;
    font-size: 2rem;
    color: $white;
  }
}

@keyframes rise {
  0% { top: 101%; background-position: 50% 100%; }
 50% { top: 0%;   background-position: 50% 0%; }
100% { top: 101%; background-position: 50% 100%; }
}


.score-sun {
  padding-top: 100%;
  position: relative;
  overflow: hidden;

  .sun {
    position: absolute;
    width: 100%;
    height: 100%;

    background: linear-gradient(to top, #A5203F, #A5203F, #CF453C, #FF6400, #F78D26, #FBAB17, #FDBF0C, #FDBF0C);
    background-size: 100% 800%;
    border-radius: 100%;
  
    text-align: center;

    top: 30%;
    background-position: 50% 95%;
    transition: 2s top, 2s background-position;

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      color: $white;
      transition: 2s height, 2s padding-top;

      height: 70%;
      padding-top: 4rem;

      .btn {
        opacity: 0;
        visibility: hidden;
        transition: 2s opacity;
      }
    }
  }

  &.score-350, &.score-550, &.score-650, &.score-700, &.score-750, &.score-850 { .sun .btn { opacity: 1; visibility: visible; } }
  &.score-350 { .sun { top: 15%; background-position: 50% 85%; .label { height:  85%; padding-top: 2rem; } } }
  &.score-550 { .sun { top: 12%; background-position: 50% 70%; .label { height:  88%; padding-top: 2rem; } } }
  &.score-650 { .sun { top:  9%; background-position: 50% 50%; .label { height:  91%; padding-top: 2rem; } } }
  &.score-700 { .sun { top:  6%; background-position: 50% 40%; .label { height:  94%; padding-top: 1rem; } } }
  &.score-750 { .sun { top:  3%; background-position: 50% 25%; .label { height:  97%; padding-top: 1rem; } } }
  &.score-850 { .sun { top:  0%; background-position: 50% 10%; .label { height: 100%; padding-top: 1rem; } } }
}
